import React from "react";
import styles from './home-page.module.scss';

const HomePage = (): JSX.Element => {
  return (
    <div className={styles.homePageContainer}>
      <div className={styles.homePageContent}>
        <h1>Hello and welcome...</h1>
        <p>
          To Stephen or Steve, I&apos;m Stephen. Currently I&apos;m a software engineer located in Atlanta, Georgia. This is a site I use to show off my work and workshop new ideas. 
        </p>
      </div>
    </div>
  )
}

export default HomePage;