import React, { useEffect, useState } from 'react';
import { ExperienceInterface } from 'common/interfaces/experience-interface';
import Modal from 'components/modal/modal';
import styles from './experience-card.module.scss';
import {ReactComponent as ExpandMore} from 'common/svgs/expand-more.svg';
import {ReactComponent as ExpandLess} from 'common/svgs/expand-less.svg';

const ExperienceCard = ( {experience, showBulletPoints, }:ExperienceCardPropsInterface ): JSX.Element => {
  const [displayBulletPoints, setDisplayBulletPoints] = useState<boolean>(showBulletPoints);
  const [showModal, setShowModal] = useState<boolean>(false);

  const modalBody: JSX.Element = (
    <>
      <h3>{experience.name}</h3>
      {experience.title && (
        <h4>{experience.title}</h4>
      )}
      {experience.date && (
        <p>{experience.date}</p>
      )}
      <p>Skills used: {experience.technologyUsed.join(', ')}</p>
      <ul>
        {experience.summary.map((point: string) => { return (<li key={point}>{point}</li>) })}
      </ul>
    </>
  );
  
  useEffect(() => {
    setDisplayBulletPoints(showBulletPoints);
  }, [showBulletPoints])

  return (
    <>
      <div className={styles.card} key={`${experience.name}-${experience.date}`}>
        <div className={styles.content} onClick={() => setShowModal(!showModal)}>
          <h3>{experience.name}</h3>
          {experience.title && (
            <h4>{experience.title}</h4>
          )}
          {experience.date && (
            <p>{experience.date}</p>
          )}
          <p className={!displayBulletPoints ? styles.collapsedEnd : ''}>Skills used: {experience.technologyUsed.join(', ')}</p>
          {displayBulletPoints && (
            <ul>
              {experience.summary.map((point: string) => { return (<li key={point}>{point}</li>) })}
            </ul>
          )}
        </div>
        <button onClick={() => setDisplayBulletPoints(!displayBulletPoints)} className={styles.button}>
          {displayBulletPoints
            ? <ExpandLess />
            : <ExpandMore />
          }
        </button>
      </div>
      <Modal
        isOpen={showModal}
        closeModal={setShowModal}
        modalTitle={experience.name}
        modalBodyContent={modalBody}
      />
    </>
  )
}

interface ExperienceCardPropsInterface {
  experience: ExperienceInterface;
  showBulletPoints: boolean;
}

export default ExperienceCard;
