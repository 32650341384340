import React, { useState } from 'react';
import TabInterface from 'common/interfaces/tab-interface';
import TabControls from 'components/tabs/tab-controls';
import ExperienceDisplay from 'components/experience-display/experience-display';
import HomePage from 'components/home-page/home-page';
import styles from './App.module.scss';
import workDataRaw from './common/work.json';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import TabRoute from 'components/tabs/tab-route';

const App: React.FC = (): JSX.Element => {
  const [tabIndex, setTabIndex] = useState<number>(0);

  const tabComponents: TabInterface[] = [
    {
      title: "Home",
      routeName: "",
      children: <HomePage />
    },
    {
      title: "Resume",
      routeName: "resume",
      children: (
        <ExperienceDisplay
          label='Professional Experience'
          experiences={workDataRaw.work.filter(filter => filter.type === "Professional")}
        />
      )
    },
    {
      title: "Projects",
      routeName: "projects",
      children: (
        <ExperienceDisplay
          label='Personal Projects'
          experiences={workDataRaw.work.filter(filter => filter.type === "Personal")}
        />
      )
    },
  ];

  return (
    <BrowserRouter>
      <div className={styles.container}>
        <div className={styles.headerBar}>
          <div className={styles.width50} title='But seriously, it&apos;s Stephen...'>
            <div className={styles.siteLabel}>
              <p>Stephen Or Steve</p>
            </div>
          </div>
          <div className={styles.width50}>
            <TabControls
              tabTitles={tabComponents.map(m => m.title)}
              tabRouteLinks={tabComponents.map(m => m.routeName)}
              activeTabIndex={tabIndex}
            />
          </div>
        </div>
        <div className={styles.bodyContainer}>
          <div className={styles.bodyLayout}>
            <div className={styles.columnContainer}>
              <Routes>
                <Route
                  path='/'
                  element={
                    <TabRoute
                      key={tabComponents[0].title}
                      tabValue={0}
                      children={tabComponents[0].children}
                      tabControl={setTabIndex}
                    />
                  }
                />
                <Route
                  path='/resume'
                  element={
                    <TabRoute
                      key={tabComponents[1].title}
                      tabValue={1}
                      children={tabComponents[1].children}
                      tabControl={setTabIndex}
                    />
                  }
                />
                <Route
                  path='/projects'
                  element={
                    <TabRoute
                      key={tabComponents[2].title}
                      tabValue={2}
                      children={tabComponents[2].children}
                      tabControl={setTabIndex}
                    />
                  }
                />
                <Route
                  path='*'
                  element={
                    <Navigate to='/' replace />
                  }
                />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
