import React from "react";
import TabTitle from "./tab-title";
import styles from './tabs.module.scss';

const TabControls = ({ tabTitles, tabRouteLinks, activeTabIndex, }: TabControlsPropsInterface): JSX.Element => {

  return (
    <>
      {tabTitles ? (
        <div className={styles.tabs}>
          <ul className={styles.tabLabels}>
            {tabTitles.map((title: string, index: number) => (
              <TabTitle
                key={title}
                title={title}
                routeAddress={tabRouteLinks[index]}
                isActive={activeTabIndex === index}
              />
            ))}
          </ul>
        </div>
      ) : (
        <span>No tabs were added.</span>
      )}
    </>
  );
}

interface TabControlsPropsInterface {
  tabTitles: string[];
  tabRouteLinks: string[];
  activeTabIndex: number;
}

export default TabControls;