import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './modal.module.scss';

const Modal = ({
  isOpen,
  closeModal,
  modalTitle,
  modalContentClassName,
  modalBodyContent,
 }: ModalPropsInterface): JSX.Element | null => {
  const [show, setShow] = useState<boolean>(false);

  const closeOnEscapeKeyPress = (event: KeyboardEvent): void => {
    if(event.isTrusted && event.code === 'Escape') {
      setShow(false);
    }
  };

  useEffect((): void => {
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    document.body.addEventListener('keydown', closeOnEscapeKeyPress);

    return function cleanup() {
      document.body.removeEventListener('keydown', closeOnEscapeKeyPress);
    };
  }, []);

  return show ? 
    ReactDOM.createPortal(
      <div className={styles.modal} onClick={() => closeModal(!show)}>
        <div className={modalContentClassName ? modalContentClassName : styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <div className={styles.modalHeader}>
            <h4 className={styles.modalTitle}>{modalTitle}</h4>
          </div>
          <div className={styles.modalBody}>
            {modalBodyContent}
          </div>
          <div className={styles.modalFooter}>
            <button onClick={() => closeModal(!show)}>Close</button>
          </div>
        </div>
      </div>,
      document.getElementById('root')!
    )
  : null;
};

Modal.defaultProps = {
  modalTitle: '',
  modalContentClassName: '',
  modalBodyContent: null,
};

interface ModalPropsInterface {
  isOpen: boolean;
  closeModal: (value: boolean) => void;
  modalTitle?: string;
  modalContentClassName?: string;
  modalBodyContent?: React.ReactNode;
}

export default Modal;