import React from "react";
import { Link } from "react-router-dom";
import styles from './tabs.module.scss';

const TabTitle = ({ title, routeAddress, isActive, }: TabTitlePropsInterface ): JSX.Element => {
  return (
    <li>
      <button className={isActive ? styles.active : ''}>
        <Link to={routeAddress} className={isActive ? styles.active : ''}>
          {title}
        </Link>
      </button>
    </li>
  );
};

interface TabTitlePropsInterface {
  title: string;
  routeAddress: string;
  isActive: boolean;
}

export default TabTitle;
