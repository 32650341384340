import React, { useEffect } from "react";

const TabRoute = ({ tabValue, children, tabControl, }: TabRoutePropsInterface): JSX.Element => {
  useEffect(() => {
    tabControl(tabValue);
  }, [tabValue, tabControl]);
  
  return <>{children}</>;
}

interface TabRoutePropsInterface {
  tabValue: number;
  children: React.ReactNode;
  tabControl: (value: number) => void;
}

export default TabRoute