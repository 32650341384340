import React, { useState } from 'react';
import { ExperienceInterface } from 'common/interfaces/experience-interface';
import ExperienceCard from 'components/experience-card/experience-card';
import styles from './experience-display.module.scss';

const ExperienceDisplay = ({ label, experiences, }: ExperienceDisplayPropsInterface): JSX.Element => {
  const [showBulletPoints, setShowBulletPoints] = useState<boolean>(true);

  const toggleBulletPoints = ():void => {
    setShowBulletPoints(!showBulletPoints);
  }

  return (
    <div className={styles.contentContainer}>
      <div className={styles.content}>
        <h1>{label}</h1>
      </div>
      <div className={styles.toggleSwitchArea}>
        <p>Show bullet points?</p>
        <div className={styles.toggleSwitch}>
          <input type='checkbox' checked={showBulletPoints} onChange={() => toggleBulletPoints()} id='toggle'/>
          <label htmlFor='toggle' />
        </div>
      </div>
      {experiences && experiences!.map((experience: ExperienceInterface) => {
        return (
          <ExperienceCard
            key={`${experience.name}-${experience.date}`}
            experience={experience}
            showBulletPoints={!!showBulletPoints}
          />
        )
      })}
    </div>
  )
}

interface ExperienceDisplayPropsInterface {
  label: string;
  experiences: ExperienceInterface[];
}

export default ExperienceDisplay;
